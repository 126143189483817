const MarketingOfficerMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'marketing-officer.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-home-4-line'
  },
  {
    title: 'User Management',
    name: 'marketing-officer.user-management',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-user-line',
    children: [
      {
        title: 'User Accounts',
        is_active: false,
        link: {
          name: 'marketing-officer.user-accounts'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-contacts-line'
      }
    ]
  },
  {
    title: 'Maintenances',
    name: 'sidebar.marketing-officer-maintenances',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-settings-line',
    children: [
      {
        title: 'Clusters',
        is_heading: false,
        is_active: false,
        link: {
          name: 'marketing-officer.clusters'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-edit-box-line'
      },
      {
        title: 'Distributions',
        is_heading: false,
        is_active: false,
        link: {
          name: 'marketing-officer.distributions'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-share-box-line'
      },
      {
        title: 'Areas',
        is_heading: false,
        is_active: false,
        link: {
          name: 'marketing-officer.areas'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-map-pin-2-line'
      },
      {
        title: 'Dealers',
        is_active: false,
        link: {
          name: 'marketing-officer.dealers'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-truck-line'
      },
      {
        title: 'Product Categories',
        is_active: false,
        link: {
          name: 'marketing-officer.product-categories'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-scan-2-line'
      },
      {
        title: 'Products',
        is_active: false,
        link: {
          name: 'marketing-officer.products'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-scan-line'
      },
      {
        title: 'Announcement',
        is_active: false,
        link: {
          name: 'marketing-officer.announcement'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-article-line'
      }
    ]
  },
  {
    title: 'Reports',
    name: 'sidebar.reports',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-chart-line',
    children: [
      {
        title: 'Report',
        is_active: false,
        link: {
          name: 'marketing-officer.report-sample'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'Report Invoice',
        is_active: false,
        link: {
          name: 'marketing-officer.report-invoice'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      }
    ]
  },
  {
    title: 'History',
    name: 'sidebar.history',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-settings-line',
    children: [
      {
        title: 'Update History',
        is_heading: false,
        is_active: false,
        link: {
          name: 'marketing-officer.history'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-edit-box-line'
      }
    ]
  }
]

export default MarketingOfficerMenu
