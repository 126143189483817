const AbmSideMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'abm.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-home-4-line'
  },
  {
    title: 'Actual Inventory',
    is_heading: false,
    is_active: false,
    link: {
      name: 'abm.moveout'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-truck-line'
  }
]

export default AbmSideMenu
